export enum EnvKey {
  REACT_APP_ENV = "REACT_APP_ENV",
  REACT_APP_NETWORK = "REACT_APP_NETWORK",
  REACT_APP_CORS_PROXY_URL = "REACT_APP_CORS_PROXY_URL",
  REACT_APP_MIXPANEL_TOKEN = "REACT_APP_MIXPANEL_TOKEN",
  REACT_APP_MIXPANEL_DEBUG_ENABLED = "REACT_APP_MIXPANEL_DEBUG_ENABLED",
  REACT_APP_LAUNCH_DARKLY_SDK_DEV = "REACT_APP_LAUNCH_DARKLY_SDK_DEV",
  REACT_APP_LAUNCH_DARKLY_SDK_PROD = "REACT_APP_LAUNCH_DARKLY_SDK_PROD",
  REACT_APP_URL = "REACT_APP_URL",
  REACT_APP_HASURA_URL_V2 = "REACT_APP_HASURA_URL_V2",
  REACT_APP_HASURA_ADMIN_SECRET_V2 = "REACT_APP_HASURA_ADMIN_SECRET_V2"
}

export enum FeatureFlag {
  lambdaDao = "lambdaDao"
}
